import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBars, faChartLine, faList, faCogs, faProjectDiagram, faDatabase, faCog, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import './../css/sidebar.css'; // Import CSS for styling if needed
import { isLoggedIn, logout } from '../../hooks/auth';  // Import utility function
import Cookies from 'js-cookie';

const iconMapping = {
    faHome,
    faChartLine,
    faList,
    faCogs,
    faProjectDiagram,
    faDatabase,
    faCog,
    faExternalLink
};

// Mock function to simulate getting user from a context or API
const fetchUser = () => {
    return {
        name: Cookies.get('name'),
        profilePic: Cookies.get('picture')
    };
};

// Mock function to simulate logout
const performLogout = () => {
    logout()
    // Add actual logout logic here (e.g., clear tokens, make API call)
};

const Sidebar = ({ items }) => {
    const [isActive, setIsActive] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Fetch user data when component mounts
        const userData = fetchUser();
        setUser(userData);
    }, []);

    const toggleSidebar = () => {
        setIsActive(!isActive);
    };

    const toggleProfileDropdown = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const handleLogout = () => {
        performLogout();
        setUser(null);  // Clear user state after logout
        // Optionally redirect to login page or other actions
    };

    return (
        <div className={`sidebar ${isActive ? 'active' : ''}`}>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
            </button>
            <img src="/logos/3m.png" height={30} alt="Logo" />
            <div className="sidebar-content">
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>
                            {item.external ? (
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={iconMapping[item.icon]} className="sidebar-icon" />
                                    {item.title}
                                </a>
                            ) : (
                                <Link to={item.link}>
                                    <FontAwesomeIcon icon={iconMapping[item.icon]} className="sidebar-icon" />
                                    {item.title}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            {user && (
                <div className="profile-section">
                    <button className="profile-button" onClick={toggleProfileDropdown}>
                        <img src={user.profilePic} alt="Profile" className="profile-pic" />
                        <span className="profile-name">{user.name}</span>
                        <FontAwesomeIcon icon={faCog} />
                    </button>
                    {isProfileOpen && (
                        <div className="profile-dropdown">
                            <button onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Sidebar;
