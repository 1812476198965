import Container from 'react-bootstrap/Container';
import React, { Component, useState, useCallback, useEffect } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ModeComment } from '@mui/icons-material';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import $ from "jquery";
import {toast} from "../../utils"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import BaseRequest from '../../Api/Base';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { workflow_apps } from '../../config/app';

class WorkflowActionsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiListContainer: this.loadingModal(),
            showModal: false,
            modalTitle: "Select a Action",
            promptInput: "",
            promptName: "",
            promptId: null,
            btnMainActionTitle: "Create new AI flow",
            selectedAppList: [],
            app: undefined,
            modalIcon: <i class="fa-solid fa-wifi"></i>,
            btnBack: ""
        }
        this.api = new BaseRequest();
    }


    getMOPLists($app, key) {
        var $parent = this;
        var $payload = {
            action: "list",
            page: 1,
            description: "",
            username: "admin",
            id: [],
        }
        this.api.python($payload).then(function (response) {
            var $items = [];
            console.log(response)
            $.each(response.data[0].items, function (i, item) {
                console.log(item)
                $items[i] = {
                    id: item.id,
                    name: item.mop_name,
                    code: item.codegen
                }
            });

            if ($items.length == 0) {
                $items[0] = {
                    id: "tmp",
                    name: "hello world",
                    code: ""
                }
            }
            $parent.updateSelectedAppList($items, $app, key);
        })
    }

    getPromptLists($app, key) {
        var $parent = this;
        this.props.home.apiQE.getPromptList().then(function (response) {
            var $items = [];
            console.log(response.data.data[0]);
            $.each(response.data.data[0].result, function (i, item) {
                $items[i] = {
                    id: item.id,
                    name: item.name
                }
            });
            $parent.updateSelectedAppList($items, $app, key);
        })
    }

    getWorkflowLists($app, key) {
        // var $parent = this;
        // this.props.home.apiANalyzer.getWFs().then(function (response) {
        //     var $items = [];
        //     $.each(response.data.data, function (i, item) {
        //         $items[i] = {
        //             id: item.id,
        //             name: item.name
        //         }
        //     });
        //     $parent.updateSelectedAppList($items, $app, key);
        // })
    }

    getMySQLLists($app, key) {
        var $parent = this;
        this.props.home.apiANalyzer.getMySQLLists().then(function (response) {
            var $items = [];
            console.log(response)
            $.each(response.data.data, function (i, item) {
                $items[i] = {
                    id: item.id,
                    name: item.dbname
                }
            });
            $parent.updateSelectedAppList($items, $app, key);
        })
    }

    getWebPipelineLists($app, key) {
        var $parent = this;
        this.props.home.apiANalyzer.getWebPipelineLists().then(function (response) {
            var $webs = response.data;
            console.log($webs);
            var $items = [];
            $.each($webs.data, function (i, item) {
                $items[i] = {
                    id: item.id,
                    name: item.name,
                    url: item.url,
                }
            });
            $parent.updateSelectedAppList($items, $app, key);
        })
    }

    getAPIPipelineLists($app, key) {
        var $parent = this;
        this.props.home.apiQE.getPromptList().then(function (response) {
            var $items = [];
            console.log(response.data.data[0]);
            $.each(response.data.data[0].result, function (i, item) {
                $items[i] = {
                    id: item.id,
                    name: item.name
                }
            });
            $parent.updateSelectedAppList($items, $app, key);
        })
    }

    // async touch($appKey, $item) {
    //     console.log($item, $appKey);
    //     if ($appKey == "PYTHON_EDITOR") {
    //         var $payload = {
    //             action: "save",
    //             type: "workflow",
    //             codegen: $item.code,
    //             question: "",
    //         }

    //         $item = await this.api.autocode($payload);
    //         return this.format_python_response($item)
    //     }
    //     return []
    // }

    format_python_response($item) {
        console.log($item)
        $item = $item.data[0].result
        var $result = {
            id: $item.id,
            name: $item.mop_name,
            code: $item.codegen
        }
        console.log($item, $result)
        return $result
    }

    async addToWorkFlow($item, $app, $appKey) {
        var level = 0
        if (this.props.home.state.workflow_items) {
            level = this.props.home.state.workflow_items.length;
        }
        else {
            // null error condition
            this.props.home.state.workflow_items = [];
        }

        // $item = await this.touch($appKey, $item);
        // console.log($item)
        var $workflow = {
            "app_id": $appKey,
            "item_id": $item.id,
            "item_name": $item.name,
            "code": $item.code,
            "level": level,
        }

        // create a new flow

        this.setState({
            showModal: false,
        })

        console.log($workflow)
        this.props.home.state.workflow_items.push($workflow);
        this.props.home.debouncedWorkflowItemsSave();
        // this.props.home.loadWorkflowItems(this.props.home.state.workflow_items);
    }

    __speak($input) {
        const synth = window.speechSynthesis;
        const utterThis = new SpeechSynthesisUtterance($input);
        synth.speak(utterThis);

        return $input;
    }

    updateSelectedAppList($items, $app, appKey) {
        var $item = [];
        if ($items) {
            for (let key in $items) {
                console.log($items)
                var $data = <div className='row'>
                    <div className='col-10'>
                        {$items[key].name}
                    </div>
                    <div className='col-2'>
                        <div className='href' onClick={this.addToWorkFlow.bind(this, $items[key], $app, appKey)}>
                            <AddCircleOutlineIcon  />
                            </div>
                    </div>
                </div>
                $item.push(<div key={key} className='col-12 list-group-item'>{$data}</div>);
            }
        }

        if ($item) {
            this.setState({
                modalContent: <div className='list-group' key={"123"}>{$item}</div>,
                modalTitle: $app.name,
            })
        }
    }

    loadAppList() {
        var modalContent = this.loadingModal();

        // var $apps = this.props.home.props.appList + this.renderer.getApps();
        var $apps = workflow_apps;

        var $apps = $.extend({}, $apps);

        console.log($apps);

        let items = [];
            for (let key in $apps) {
                var label = 
                <Button
                    className="w-100 pipeline-item border-1 bg-dark" 
                    onClick={this.getActionListFromApps.bind(this, key, $apps[key])}
                    id={key}
                    name="selected_engine"
                    body
                >
                <span className='pipeline-name col-6'>
                    <div className='row'>
                        <Col xs={3}>
                            { $apps[key].img != undefined ?
                                <Image width={40} src={$apps[key].img} rounded />
                                :
                                $apps[key].icon
                            }
                            
                        </Col>
                        <Col xs={9} className='text-truncate text-start'>
                        <span className='text'>{$apps[key].name}</span>
                        </Col>
                    </div>
                </span>
                </Button>;
            
            // Return the element. Also pass key
            items.push(<div key={key} className={"col-6 p-1"}>
                    {label}
                </div>)
        }

        modalContent = <div className='d-flex'>{items}</div>

        this.setState({
            modalContent: modalContent,
            modalTitle: "Apps",
            btnBack: <></>,
        })
    }
    componentDidMount() {
        this.loadAppList();
    }

    handleBackBtnClick() {
        this.loadAppList();
    }

    getActionListFromApps(key, $app) {
        console.log(key)
        this.setState({
            btnBack: <div className='d-inline href mx-2' onClick={this.handleBackBtnClick.bind(this)}><ArrowBackIcon /></div>
        });
        switch (key) {
            case "PYTHON_EDITOR":
                this.getMOPLists($app, key);
                break;
            case "PROMPT_STORE":
                this.getPromptLists($app, key);
                break;

            case "PIPELINE_WEB":
                this.getWebPipelineLists($app, key);
                break;

            case "RENDERER":
                // this.getRendererLists($app, key);
                break;

            case "WORKFLOW":
                this.getWorkflowLists($app, key);
                break;
            case "MY_SQL":
                this.getMySQLLists($app, key);
                toast("In Mysql !: " + key, "success");
                break;
            default: toast("comming soon !: " + key, "success");
        }
    }

    getModal () {

        return (
            <>
                <div className='text-center' id='modal-contaniner'>
                    <Modal
                    backdrop="static"
                    id='modal-action-list'
                    keyboard={false}
                    size={"lg"}
                    show={this.props.home.state.showModal} onHide={this.props.home.handleCloseModal.bind(this)}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.btnBack}
                            <span className='p-1 px-2'>{this.state.modalTitle}</span>
                                {this.state.modalIcon}
                            </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='p-0' id='action-body'>
                        {this.state.modalContent}
                    </Modal.Body>
                    </Modal>

                </div>
            </>
        );
    }

    render() {
        return this.getModal();
    }

    loadingModal () {
        return (<div className='container'>
            <Placeholder xs={12} bg="secondary" />
            <Placeholder xs={12} bg="success" />
            <Placeholder xs={12} bg="danger" />
            <Placeholder xs={12} bg="warning" />
            <Placeholder xs={12} bg="info" />
          
    </div>)
    }
}

export default WorkflowActionsList;