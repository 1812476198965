import React, { Component, createRef } from 'react';
import './../../css/editor.css';
import { Button, Form, InputGroup, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { workflow_apps } from '../../../config/app';
import BaseRequest from '../../../Api/Base';
import $ from "jquery";
import ConfigureArea from './components/ConfigureArea';
import ResultArea from './components/ResultArea';
import PythonEditor from './components/PythonEditor';
import { debounce } from 'lodash';
import { faExpandAlt, faSave, faCheckCircle, faExclamationCircle, faCaretDown, faCaretUp, faRocket, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: '', // State to hold the code status
      errorMessage: '', // State to hold the error message
      loadingCodeGeneration: false,
      isExecuteLoading: false,
      execution_log: "your results will be displayed here",
      isCollapsedHeader: false,
    };
    this.wrapper = createRef(); // Ref for the CodeMirror wrapper

    this.saving = false;
    this.debouncedSaveCode = debounce(this.saveCode, 1000);
    this.api = new BaseRequest();
    console.log(this)
  }

  handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault(); // Prevent the default save action

      if (this.saving === "processing") {
        return;
      }
      this.saving = "processing";

      // Call save API
      const payload = this.getPythonPayload();
      const level = this.props.parent.state.active_level;
      payload["action"] = "save";
      payload["codegen"] = this.props.parent.state.workflow_items[level].code;
      payload["mop_name"] = this.props.parent.state.workflow_items[level].item_name;

      console.log('Ctrl + S was pressed!');
      // Your custom logic here
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.debouncedSaveCode.cancel();
  }

  handleTabClick = (item, $parent) => {
    console.log($parent)
    $parent.setState({
      activeTab: "level_" + item.level + "_" + item.item_id,
      code: $parent.state.workflow_items[item.level].code,
      prompt: $parent.state.workflow_items[item.level].question,
      active_level: item.level,
    });
  };

  saveCode = async () => {
    var $payload = this.getPythonPayload();
    console.log($payload, this)
    this.setState({ codeStatus: 'Saving...', errorMessage: '' });
    try {
      var $result = await this.api.autocode($payload);
      console.log($result)
      this.setState({ codeStatus: 'Saved' });
      console.log('Code saved successfully');
    } catch (error) {
      this.setState({ codeStatus: 'Failed to save', errorMessage: error.message });
      console.error('Failed to save code:', error);
    } finally {
      this.saving = false;
      // setTimeout(() => {
      //   this.setState({ codeStatus: '' });
      // }, 2000); // Clear the status after 2 seconds
    }
  };

  getPythonPayload() {
    const level = this.props.parent.state.active_level;
    var $promt = this.props.parentState.prompt;
    $promt = "User Question: " + $promt + "\n" + "My current Code: " + this.props.parentState.code;

    return {
        question: $promt,
        mop_name: this.props.parent.state.workflow_items[level].item_name,
        id: this.props.parentState.item_id,
        action: "edit",
        version: "v1",
        inputdata: "{'in_type': '','in_api': '','in_query_id': ''}",
        description: "this is a autocode api",
        codegen: this.props.parentState.code,
        username: "admin",
        modelname: "mistral"
    };
  }

  async handlePythonCodeExecute() {
    this.setState({
      isExecuteLoading: true,
    })
    const payload = this.getPythonPayload();
    payload["action"] = "execute";
    try {
      const res = await this.api.autocode(payload);
      this.setState({
        isExecuteLoading: false,
      })

      this.setState({
        execution_log: res.data[0].execution_log 
      })
    } catch (error) {
      console.error('Failed to execute code:', error);
    }
  }

  handleExpand = () => {
    this.props.parent.handleShowEditorModal(this.props.parent.editor);
  };

  async handleEditWithAI() {
    this.setState({
      loadingCodeGeneration: true
    })
    const payload = this.getPythonPayload();
    payload["action"] = "generate";
    try {
      const res = await this.api.autocode(payload);
      this.props.parent.setState({
        code: res.data[0].result.program_generated
      })
      this.setState({
        loadingCodeGeneration: false
      })
    } catch (error) {
      console.error('Failed to execute code:', error);
    }
  }

  updateCode = (newCode) => {
    console.log(newCode)

    this.props.parent.setState({ code: newCode });
    this.setState({ codeStatus: 'Saving...', errorMessage: '' });
    this.debouncedSaveCode();
  };

  handlePromptChange = (prompt) => {
    this.props.parent.setState({ prompt: prompt.target.value });
    this.setState({ codeStatus: 'Saving...', errorMessage: '' });
    this.debouncedSaveCode();
  };

  handleExpandHeader() {
    this.setState({
      isCollapsedHeader: !this.state.isCollapsedHeader
    })
  }

  render() {
    const { items } = this.props;
    const { activeTab, code } = this.props.parentState;
    const { codeStatus, errorMessage } = this.state;

    console.log(this)
    if (!items || items.length < 1) {
      return null;
    }

    let statusIcon;
    let tooltipMessage = '';
  
    switch (codeStatus) {
      case 'Saving...':
        statusIcon = <FontAwesomeIcon icon={faSave} className="text-warning" />;
        tooltipMessage = 'Saving...';
        break;
      case 'Saved':
        statusIcon = <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
        tooltipMessage = 'Code saved successfully';
        break;
      case 'Failed to save':
        statusIcon = (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-error" style={{ position: 'fixed' }}>
                {errorMessage}
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" />
          </OverlayTrigger>
        );
        tooltipMessage = errorMessage;
        break;
      default:
        statusIcon = null;
    }

  
    return (
      <div className="editor h-100">
        <header className="editor-header">
          <nav>
            <ul>
              {items.map((item) => (
                <li
                  key={item.item_id}
                  className={activeTab === "level_" + item.level + "_" + item.item_id ? 'active' : ''}
                  onClick={() => this.handleTabClick(item, this.props.parent)}
                >
                  <Image src={workflow_apps[item.app_id].img} height={20} className="m-auto pe-2 file-icon" />
                  {item.item_name} {activeTab === "level_" + item.level + "_" + item.item_id ? statusIcon : ""}
                </li>
              ))}
            <Button
            onClick={() => this.handleExpandHeader.bind(this)}
            aria-controls="ai-section"
            // aria-expanded={!isCollapsed}
            variant="light"
            className='d-inline float-end ms-auto'
          >
            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} /> 
          </Button>
            </ul>

          
          </nav>
        </header>
        <main className="editor-main mt-2">
          {/* <div className="editor-section px-1 configure-section">
            <ConfigureArea />
          </div> */}
          <PythonEditor
            handleExpand={this.handleExpand}
            handleCodeChange={this.updateCode}
            currentCode={this.props.parentState.code}
            codeStatus={codeStatus}
            errorMessage={errorMessage}
            handleEditWithAI={this.handleEditWithAI.bind(this)}
            currentPrompt={this.props.parentState.prompt}
            handlePromptChange={this.handlePromptChange}
            loading={this.state.loadingCodeGeneration}
            handlePythonCodeExecute={this.handlePythonCodeExecute.bind(this)}
          />
          <div className="editor-section px-1 result-section mb-5">
            <ResultArea 
              result={this.state.execution_log}
            />
          </div>
          
        </main>
      </div>
    );
  }
}

export default Editor;
