// ResultArea.js
import React from 'react';

const ResultArea = ({result}) => (
  <div className="result-area">
    <h4>RESULT</h4>
    <div className=''>{result}</div>
  </div>
);

export default ResultArea;
