import React from 'react';
import { Form } from 'react-bootstrap'; // Make sure you have react-bootstrap installed

const IntervalDropdown = ({ interval, handleIntervalChange }) => {
  return (
    <Form.Control
      as="select"
      value={interval}
      onChange={handleIntervalChange}
      className="interval-dropdown border-0 ms-2 col ps-2"
    >
      <option value="1m">1 Minute</option>
      <option value="1h">1 Hour</option>
      <option value="1d">1 Day</option>
    </Form.Control>
  );
};

export default IntervalDropdown;
