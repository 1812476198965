import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import AppNavbar from '../common/header';
import "./css/common.css"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseRequest from '../Api/Base';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';

class LogicBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnSubmitDisable: false,
      btnSubmitName: "Apply Logic",
      ticker: '',
      last_price: '',
      buyCondition: {
        currentPrice: '',
        condition: 'greater',
        price: ''
      },
      sellCondition: {
        currentPrice: '',
        condition: 'greater',
        price: ''
      },
      stopLoss: '',
      stopProfit: '',
      tradingAmount: ''
    };
    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.state.ticker = params.get('symbol');
    this.api = new BaseRequest({ host: '' });
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const ticker = params.get('symbol');
    if (ticker) {
      this.setState({ ticker });

      this.fetchCurrentPrice(ticker);
      // this.interval = setInterval(() => {
      //   this.fetchCurrentPrice(ticker);
      // }, 2000); // 2000 milliseconds = 2 seconds

      
    }
  }

  fetchCurrentPrice = () => {
    const { ticker } = this.state;  // Assuming this.state.ticker holds the symbol
  
    var $payload = {
      "symbols": [ticker]
    }
    
    this.api.post(`api/market/quotes`, $payload)
      .then(response => response)
      .then(data => {
        console.log(data);
        if (data[0]) {
          this.setState(data[0]);  // Update state correctly

        }
        // Log data instead of response
      })
      .catch(error => {
        console.error('Error fetching current price:', error);
        // Handle error if needed
      });
  };
  

  handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'ticker') {
      this.setState({ ticker: value });
    } else if (name.startsWith('buyCondition')) {
      const updatedBuyCondition = { ...this.state.buyCondition };
      updatedBuyCondition[name.split('.')[1]] = value;
      this.setState({ buyCondition: updatedBuyCondition });
    } else if (name.startsWith('sellCondition')) {
      const updatedSellCondition = { ...this.state.sellCondition };
      updatedSellCondition[name.split('.')[1]] = value;
      this.setState({ sellCondition: updatedSellCondition });
    } else {
      this.setState({ [name]: value });
    }
  };
  

  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(this.state);

    var payloads = {
      instrument_name: this.state.instrument_name ?? this.state.ticker,
      buy_price: this.state.last_price,
      target_stop_loss: this.state.stopLoss,
      target_stop_profit: this.state.stopProfit,
      buy_quantity: this.state.tradingAmount,
      buy_condition_logic: this.state.buyCondition.condition,
      buy_condition_price: this.state.buyCondition.price,
      sell_condition_logic: this.state.sellCondition.condition,
      sell_condition_price: this.state.sellCondition.price,
    }
    console.log(payloads);
    var $parent = this;
    this.api.post(`api/market/paper_trading`, payloads)
      .then(function($res) {
        $parent.setState({ 
          btnSubmitDisable: true,
          btnSubmitName: $res.msg,
         });
        console.log($res);
      })

    // Example: Send data to backend or perform further actions
  };

  render() {
    const { ticker, buyCondition, sellCondition, stopLoss, stopProfit, tradingAmount } = this.state;

    return (
      <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
      <div className="flex-fill mt-4 w-100 px-2">
          <h1 className="mb-4">Paper Trading Setting</h1>
          <Form onSubmit={this.handleSubmit} className="paper-trading-form">
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Stock Ticker</Form.Label>
              <Form.Control
                type="text"
                name="ticker"
                value={ticker}
                disabled
                onChange={this.handleChange}
              />
              
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Price</Form.Label>
                <Form.Control
                  type="text"
                  name="last_price"
                  value={this.state.last_price}
                  onChange={this.handleChange}
                  disabled
                />
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Buy Condition:</Form.Label>
              <Form.Control
                as="select"
                name="buyCondition.condition"
                value={buyCondition.condition}
                onChange={this.handleChange}
              >
                <option value="greater">Greater than</option>
                <option value="smaller">Smaller than</option>
              </Form.Control>
              <Form.Control
                type="text"
                name="buyCondition.price"
                value={buyCondition.price}
                onChange={this.handleChange}
                placeholder="Specific Price"
              />
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Sell Condition:</Form.Label>
              <Form.Control
                as="select"
                name="sellCondition.condition"
                value={sellCondition.condition}
                onChange={this.handleChange}
              >
                <option value="greater">Greater than</option>
                <option value="smaller">Smaller than</option>
              </Form.Control>
              <Form.Control
                type="text"
                name="sellCondition.price"
                value={sellCondition.price}
                onChange={this.handleChange}
                placeholder="Specific Price"
              />
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Stop Loss:</Form.Label>
              <Form.Control
                type="text"
                name="stopLoss"
                value={stopLoss}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Stop Profit:</Form.Label>
              <Form.Control
                type="text"
                name="stopProfit"
                value={stopProfit}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className='d-flex'>
              <Form.Label className='col-3'>Trading Amount:</Form.Label>
              <Form.Control
                type="text"
                name="tradingAmount"
                value={tradingAmount}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Button type="submit" className="btn btn-primary mt-3" disabled={this.state.btnSubmitDisable}>
              {this.state.btnSubmitName}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default LogicBuilder;
