// EditWorkflowModal.js
import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class EditWorkflowModal extends Component {
  constructor(props) {

    console.log(props)
    super(props);
    this.state = {
      showModal: props.showModal,
      workflowName: '',
      timeout: 1,
      execution_interval: 1,
      modal_title: "Create new Workflow"
    };

    console.log(props)
    if (props.edit_workflow_props) {
        var workflow = props.edit_workflow_props.workflow;
        this.state.workflowName = workflow.name;
        this.state.execution_interval = workflow.execution_interval;
        this.state.timeout = workflow.timeout;
        this.state.id = workflow.id;
        this.state.modal_title = "Edit Workflow details";
    }
  }

  handleClose = () => {
    this.setState({ showModal: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleShow = () => {
    this.setState({ showModal: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSave = () => {
    console.log(this.state)
    console.log(this.props)
    // Implement save logic here
    if (this.props.edit_workflow_props) {
        console.log(this.props.edit_workflow_props)
        this.props.edit_workflow_props.onSave({
            workflowName: this.state.workflowName,
            timeout: this.state.timeout,
            execution_interval: this.state.execution_interval,
            });
    }
    else {
        this.props.onSave({
            workflowName: this.state.workflowName,
            timeout: this.state.timeout,
            execution_interval: this.state.execution_interval,
        });
    }
    this.handleClose();
  };

  render() {

    var $handle_button = <Button
            onClick={this.handleShow}
            id="btn-create-workflow"
            name="selected_engine"
            size='sm'
            className={this.props.class ?? "bg-dark "}
        >
            {this.props.title}
    </Button>
    if (this.props.btn_modal) {
        $handle_button = this.props.btn_modal;
        $handle_button = <div className='btn-link' onClick={this.handleShow}>{$handle_button}</div>
    }


    console.log(this.props)
    return (
      <div className=''>
        {$handle_button}

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modal_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formWorkflowName" className="form-group">
                <Form.Label className="form-label border-0">Workflow Name</Form.Label>
                <Form.Control
                  type="text"
                  name="workflowName"
                  value={this.state.workflowName}
                  onChange={this.handleChange}
                  placeholder="Enter workflow name"
                  className="form-control"
                />
              </Form.Group>

              <div className="d-flex align-items-center mb-3">
                <Form.Group controlId="formTimeout" className="flex-grow-1 me-3">
                  <Form.Label className="slider-label border-0">
                    Timeout: <span className="slider-value"> {this.state.timeout}s</span>
                  </Form.Label>
                  <Form.Range
                    name="timeout"
                    min="1"
                    max="720"
                    value={this.state.timeout}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formTimeoutNumber" className="flex-shrink-1">
                  <Form.Control
                    type="number"
                    name="timeout"
                    value={this.state.timeout}
                    min="1"
                    max="720"
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Timeout (seconds)"
                  />
                </Form.Group>
              </div>

              <div className="d-flex align-items-center mb-3">
                <Form.Group controlId="formExecutionInterval" className="flex-grow-1 me-3">
                  <Form.Label className="slider-label border-0">
                    Execution Interval: <span className="slider-value"> {this.state.execution_interval}s</span>
                  </Form.Label>
                  <Form.Range
                    name="execution_interval"
                    min="1"
                    max="720"
                    value={this.state.execution_interval}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formExecutionIntervalNumber" className="flex-shrink-1">
                  <Form.Control
                    type="number"
                    name="execution_interval"
                    value={this.state.execution_interval}
                    min="1"
                    max="720"
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Execution Interval (seconds)"
                  />
                </Form.Group>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSave}>
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditWorkflowModal;
