import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchForm = ({ searchQuery, handleSearchChange, handleSearchSubmit, placeholder = "Search Trading Symbol", className = "" }) => {
  return (
    <Form onSubmit={handleSearchSubmit} className={`search-form col rounded-0 ${className}`}>
      <FontAwesomeIcon icon={faSearch} className='ps-2' />
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-input border-0 fw-bolder"
      />
    </Form>
  );
};

export default SearchForm;
