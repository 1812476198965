import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import "./../css/breadcrumb.css";

const Breadcrumb = ({ items }) => {
  return (
    <nav className="breadcrumb m-0">
      <ul className="breadcrumb-list">
        <li className="breadcrumb-item">
          <Link to="/">
            <FontAwesomeIcon icon={faHome} />
            <span className='ps-2'>Home</span>
          </Link>
          <FontAwesomeIcon icon={faAngleRight} className="breadcrumb-separator" />
        </li>
        {items.map((item, index) => (
          <li className="breadcrumb-item" key={index}>
            {item.url ? (
              <>
                <Link to={item.url}>{item.name}</Link>
                <FontAwesomeIcon icon={faAngleRight} className="breadcrumb-separator" />
              </>
            ) : (
              <span>{item.name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
