import React, { Component } from 'react';
import { Table, Spinner, Form } from 'react-bootstrap';
import AppNavbar from '../common/header';
import BaseRequest from '../Api/Base';
import "./css/common.css"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'; // lodash for debouncing
import moment from 'moment';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';
import SearchForm from './components/search_box';

class PaperTradingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruments: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: '',
    };

    // this.handleScroll = _.debounce(this.handleScroll.bind(this), 300); // Debounce handleScroll
    this.fetchInstruments = this.fetchInstruments.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.fetchInstruments();
    // this.interval = setInterval(() => {
    //     this.fetchInstruments();
    // }, 2000); // 2000 milliseconds = 2 seconds
    // window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async fetchInstruments() {
    if (this.state.loading || !this.state.hasMore) return; // Prevent multiple calls
    // this.setState({ loading: true });
    this.state.loading = true;
    const { page, searchQuery } = this.state;
    console.log(page, searchQuery);
    try {
      const data = await this.api.get('/api/market/paper_trading', { page, search: searchQuery });
      console.log('API response:', data); // Debugging line to check the structure of the response
  
      if (data && data) {
        this.setState((prevState) => ({
          // instruments: [...prevState.instruments, ...data.results],
          instruments: [...data],
          hasMore: data.length > 0,
          loading: false,
        }));
      } else {
        console.error('Unexpected API response structure:', data);
        this.setState({ loading: false, hasMore: false });
      }
    } catch (error) {
      console.error('Error fetching instruments:', error);
      this.setState({ loading: false });
    }
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || this.state.loading) return;
    console.log("scrolled....")
    this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchInstruments);
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ instruments: [], page: 1, hasMore: true }, this.fetchInstruments);
  }

  render() {
    return (
      <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
        <div className="flex-fill mt-4 px-2">
          <h1 className="mb-4">Trades Execution on Paper</h1>
          <SearchForm
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
            placeholder="Enter symbol..."
            className="my-custom-class"
          />
          {this.getInstruments()}
        </div>
      </div>
    );
  }

  getInstruments() {
    return (
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>time_triggered</th>
              <th>algo_name</th>
              <th>instruments</th>
              <th>buy_quantity</th>
              <th>buy_price</th>
              <th>current_price</th>
              <th>change_percent</th>
              <th>profit_loss</th>
              <th>target_stop_loss</th>
              <th>target_profit</th>
              <th>current_index_value</th>
              <th>buying_index_value</th>
              <th>status</th>
              <th>logs</th>
              <th>add_to_portfolio</th>
              <th>created_at</th>
              <th>updated_at</th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {this.state.instruments.map((instrument) => (
              <tr key={instrument.id} className={instrument.status}>
                <td>{instrument.id}</td>
                <td>{instrument.time_triggered}</td>
                <td>{instrument.algo_name}</td>
                <td>{instrument.instruments}</td>
                <td>{instrument.buy_quantity}</td>
                <td>{instrument.buy_price}</td>
                <td>{instrument.current_price}</td>
                <td style={{ color: instrument.change_percent >= 0 ? 'green' : 'red' }}>
                  {parseFloat(instrument.change_percent).toFixed(2)}
                </td>
                <td style={{ color: instrument.change_percent >= 0 ? 'green' : 'red' }}>
                  {parseFloat(instrument.profit_loss).toFixed(2)}
                </td>
                <td>{instrument.target_stop_loss}</td>
                <td>{instrument.target_profit}</td>
                <td>{instrument.current_index_value}</td>
                <td>{instrument.buying_index_value}</td>
                <td>{instrument.status}</td>
                <td>{instrument.logs}</td>
                <td>{instrument.add_to_portfolio}</td>
                <td>{moment(instrument.created_at).fromNow()}</td>
                <td>{moment(instrument.updated_at).fromNow()}</td>
                {/* Add more columns as needed */}
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.loading && (
          <div className="text-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!this.state.hasMore && !this.state.loading && (
          <p className="mt-3 text-center">No more instruments to load.</p>
        )}
      </div>
    );
  }
}

export default PaperTradingList;
