import React, { Component } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import Sidebar from '../components/LeftSidebar';
import sidebarItems from '../components/sidebarItems.json';
import BaseRequest from '../../Api/Base';
import { toast } from '../../utils';

class SymbolPublisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      symbolName: '',
      symbolTypes: {
        stock_index: false,
        stock_option: false,
        crypto: false,
        forex: false,  // Add forex here
        selectedSymbolType: 'crypto',  // Default selection (if any)
      },
      validated: false,
      submitEnabled: false,
    };
    this.api = new BaseRequest();
  }

  handleInputChange = (event) => {
    this.setState({ symbolName: event.target.value });
  };

  handleCheckboxChange = (event) => {
    const { value } = event.target;

    // Update the state based on the selected radio button
    this.setState({
      selectedSymbolType: value,
      symbolTypes: {
        stock_index: value === 'stock_index',
        stock_option: value === 'stock_option',
        crypto: value === 'crypto',
        forex: value === 'forex',  // Correctly update the forex state
      },
    });
  };

  handleValidate = async () => {
    const { symbolName, symbolTypes } = this.state;
    const isAnyTypeSelected = Object.values(symbolTypes).some((isSelected) => isSelected);

    if (!symbolName || !isAnyTypeSelected) {
      toast('Please enter a symbol name and select at least one symbol type.');
      return;
    }

    var $result = await this.api.symbol_publish({
      symbol: symbolName,
      type: symbolTypes,
      action: "validate"
    });

    if ($result) {
      if ($result.status === 400) {
        toast($result.data.detail);
      } else {
        toast($result.message);
        this.setState({ validated: true, submitEnabled: true });
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { symbolName, symbolTypes, selectedSymbolType } = this.state;
    const isAnyTypeSelected = Object.values(symbolTypes).some((isSelected) => isSelected);

    if (!symbolName || !isAnyTypeSelected) {
      toast('Please enter a symbol name and select at least one symbol type.');
      return;
    }

    var $result = await this.api.symbol_publish({
      symbol: symbolName,
      type: selectedSymbolType,
      action: "publish"
    });

    if ($result) {
      if ($result.status === 400) {
        toast($result.data.detail);
      } else {
        toast($result.message);
        this.setState({ validated: true, submitEnabled: true });
      }
    }
  };

  render() {
    const { symbolName, symbolTypes, validated, submitEnabled } = this.state;

    return (
      <Container fluid className="d-flex p-0">
        {/* Sidebar */}
        <Sidebar items={sidebarItems} />

        {/* Main content */}
        <div className="flex-fill d-flex justify-content-center align-items-center py-4">
          <Card className="p-4 shadow" style={{ width: '500px', borderRadius: '10px' }}>
            <h2 className="text-center mb-4">Publish a Symbol</h2>
            <Form onSubmit={this.handleSubmit}>
              {/* Symbol Name */}
              <Form.Group as={Row} controlId="formSymbolName">
                <Form.Label column sm="4">
                  Symbol Name:
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    value={symbolName}
                    onChange={this.handleInputChange}
                    disabled={validated}
                    placeholder="Enter symbol name"
                  />
                </Col>
              </Form.Group>

              {/* Symbol Type */}
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  Symbol Type:
                </Form.Label>
                <Col sm="8">
                  <div className="d-flex flex-wrap">
                    <Form.Check
                      type="radio"
                      label="Stock Index"
                      value="stock_index"
                      id="stock_index"
                      name='check_type'
                      checked={symbolTypes.stock_index}
                      onChange={this.handleCheckboxChange}
                      disabled={validated}
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label="Stock Option"
                      value="stock_option"
                      id="stock_option"
                      name='check_type'
                      checked={symbolTypes.stock_option}
                      onChange={this.handleCheckboxChange}
                      disabled={validated}
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label="Crypto"
                      value="crypto"
                      id="crypto"
                      name='check_type'
                      checked={symbolTypes.crypto}
                      onChange={this.handleCheckboxChange}
                      disabled={validated}
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      label="Forex"
                      value="forex"
                      id="forex"
                      name='check_type'
                      checked={symbolTypes.forex}
                      onChange={this.handleCheckboxChange}
                      disabled={validated}
                    />
                  </div>
                </Col>
              </Form.Group>

              {/* Action Buttons */}
              <Row className="mt-4">
                <Col className="text-end">
                  <Button variant="primary" onClick={this.handleValidate} className="me-2" disabled={submitEnabled}>
                    Validate
                  </Button>
                  <Button variant="success" type="submit" disabled={!submitEnabled}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </Container>
    );
  }
}

export default SymbolPublisher;
