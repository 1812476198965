import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import BaseRequest from './../src/Api/Base';  // Assuming BaseRequest is your API handler
import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';  // Import useNavigate

const Login = () => {
    const api = new BaseRequest();
    // const navigate = useNavigate();  // Use useNavigate instead of useHistory

    const handleLoginSuccess = async (response) => {
        try {
            const token = response.credential;  // Get the Google OAuth credential (JWT)
            console.log('Google token:', token);

            const params = { code: token };
            const data = await api.auth_google(params);
            console.log('Response from backend:', data);

            if (data.access_token) {
                console.log('Login successful:', data);
                Cookies.set('access_token', data.access_token, { expires: 7 });
                Cookies.set('user_email', data.email);
                Cookies.set('name', data.name);
                Cookies.set('picture', data.picture);
                window.location.href = "/"
                // Redirect to the dashboard or home page
                // navigate('/dashboard');  // Use navigate to programmatically navigate
            } else {
                console.error('Login failed:', data);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login failed:', error);
    };

    return (
        <GoogleOAuthProvider clientId="911333972651-0j9jrhs844kvtb0v3l6dve550d376hq7.apps.googleusercontent.com">
            <div className="login-container my-5">
                <div className="login-content">
                    <h1 className='text-white'>Welcome to Money Making Machine</h1>
                    <p className='text-white'>Experience seamless trading with our advanced tools and insights.</p>
                    <div className='d-flex justify-content-center'>
                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginFailure}
                            text="Login with Google"
                            className="m-auto text-center"
                            logo_alignment="center "
                        />
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
