import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { ROUTE } from '../config/routes';
import './AppNavbar.css';

function AppNavbar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top custom-navbar">
      <Container>
        
      </Container>
    </Navbar>
  );
}

export default AppNavbar;
