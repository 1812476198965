import React, { Component } from 'react';
import { Table, Spinner, Form, Button } from 'react-bootstrap';
import BaseRequest from '../../Api/Base';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { toast } from "./../../utils";
import Sidebar from '../components/LeftSidebar';
import sidebarItems from '../components/sidebarItems.json';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchForm from '../components/search_box';

class PythonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      hasMore: true,
      items: [],
      searchQuery: '',
      showModal: false,
      fetchedItemIds: new Set(),
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSavePythonList = this.handleSavePythonList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    // this.fetchPythonLists = this.fetchPythonLists.bind(this);

    this.api = new BaseRequest();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  async handleSavePythonList(PythonListData) {
    console.log('PythonList Data:', PythonListData);

    try {
      const $payload = {
        name: PythonListData.PythonListName,
        status: "draft",
        timeout: parseInt(PythonListData.timeout, 10),
        execution_interval: parseInt(PythonListData.execution_interval, 10),
      };

      // const response = await this.api.PythonList("create", $payload);

      // if (response.id) {
      //   toast('PythonList created successfully!');
      //   this.fetchPythonLists();
      //   this.handleClose();
      // } else {
      //   toast('Failed to create PythonList. Please try again.', "error");
      // }
    } catch (error) {
      toast('An error occurred while creating the PythonList. Please try again.', "error");
    }
  }

  componentDidMount() {
    toast("Loaded ...")
    this.fetchPythonLists();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async fetchPythonLists() {
    if (this.state.loading || !this.state.hasMore) return;

    this.setState({ loading: true });
    const { page, searchQuery } = this.state;

    try {
      const data = await this.api.python({
        pagination: {
          skip: (page - 1) * 100,
          limit: 100,
        },
        action: "list",
        query: searchQuery,
      });

      const $items = data.data[0].items || [];
      const newFetchedItemIds = new Set(this.state.fetchedItemIds);

      // Filter out items that are already fetched
      const uniqueItems = $items.filter(item => {
        if (newFetchedItemIds.has(item.id)) {
          return false;
        }
        newFetchedItemIds.add(item.id);
        return true;
      });

      this.setState(prevState => ({
        items: [...prevState.items, ...uniqueItems],
        hasMore: uniqueItems.length === 100, // Assuming 100 is the limit
        loading: false,
        fetchedItemIds: newFetchedItemIds,
        page: prevState.page + 1, // Increment page number
      }));
    } catch (error) {
      console.error('Error fetching PythonLists:', error);
      this.setState({ loading: false });
    }
  }

  handleScroll() {
    // Check if scrolled to bottom and not already loading
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      if (!this.state.loading && this.state.hasMore) {
        this.fetchPythonLists(); // Fetch more items
      }
    }
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    // Reset state on new search
    this.setState({ items: [], page: 1, hasMore: true, fetchedItemIds: new Set() }, this.fetchPythonLists);
  }

  render() {
    return (
      <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
        <div className="flex-fill mt-4 w-100">
          <h1 className="mb-4">Events Library for Alarms</h1>
          <SearchForm
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
            placeholder="Enter symbol..."
            className="my-custom-class"
          />
          {this.renderPythonLists()}
        </div>
      </div>
    );
  }

  renderPythonLists() {
    return (
      <div className="list-container mt-1">
        <a href={"edit/" + crypto.randomUUID()} className='bg-dark btn btn-primary btn-sm'>
          <AddCircleOutlineIcon /> <span>Create New Event</span>
        </a>
        {this.state.items.length === 0 ? (
          <div className="text-center my-5">
            <h3>No Logics Found</h3>
            <p className="text-muted">It looks like you haven't created any Logics yet.</p>
            <Button
              variant="primary"
              onClick={this.handleShow}
              className="mt-3"
            >
              Create Your First PythonList
            </Button>
          </div>
        ) : (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((python) => (
                  <tr key={python.id}> {/* Ensure unique key */}
                    <td>
                      <a href={"edit/" + python.id} className="btn btn-link text-decoration-none">
                        {python.mop_name}
                      </a>
                    </td>
                    <td>{moment(python.created_at).fromNow()}</td>
                    <td>{moment(python.updated_at).fromNow()}</td>
                    <td>
                      <a href={"edit/" + python.id} className="btn btn-link">
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.state.loading && (
              <div className="text-center my-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            {!this.state.hasMore && !this.state.loading && (
              <p className="mt-3 text-center">No more PythonLists to load.</p>
            )}
          </>
        )}
      </div>
    );
  }
}

export default PythonList;
