import React, { Component, createRef } from 'react';
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BaseRequest from '../../Api/Base';
import { debounce } from 'lodash';
import { faSave, faCheckCircle, faExclamationCircle, faUpRightAndDownLeftFromCenter, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResultArea from '../Workflow/Editors/components/ResultArea';
import PythonEditor from '../Workflow/Editors/components/PythonEditor';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/LeftSidebar';
import sidebarItems from '../components/sidebarItems.json';
import Badge from 'react-bootstrap/Badge';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import $ from "jquery";

class EditorPython extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: '',
      errorMessage: '',
      loadingCodeGeneration: false,
      isExecuteLoading: false,
      execution_log: "your results will be displayed here",
      isCollapsedHeader: false,
      id: props.id, // Use id passed as prop
      active_level: 0, // Set default active level
      code: '',
      prompt: '',
      workflow_items: [],
    };
    this.wrapper = createRef();
    this.saving = false;
    this.debouncedSaveCode = debounce(this.saveCode, 1000);
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.updateIde();
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.debouncedSaveCode.cancel();
  }

  handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      if (this.saving === "processing") {
        return;
      }
      this.saving = "processing";
      this.saveCode();
    }
  };

  updateIde = async () => {
    const payload = { 
        ids: [this.state.id],
        action: "list"
     };
    var $result = await this.api.python(payload)
    this.setState($result.data[0].items[0])
    // Fetch and set initial state if needed
  };

  handleTabClick = (item) => {
    const { parent } = this.props;
    if (parent) {
      parent.setState({
        activeTab: `level_${item.level}_${item.item_id}`,
        code: parent.state.workflow_items[item.level].code,
        prompt: parent.state.workflow_items[item.level].question,
        active_level: item.level,
      });
    }
  };

  saveCode = async () => {
    const payload = this.getPythonPayload();
    this.setState({ codeStatus: 'Saving...', errorMessage: '' });
    try {
      const result = await this.api.autocode(payload);
      this.setState({ codeStatus: 'Saved' });
      console.log('Code saved successfully:', result);
    } catch (error) {
      this.setState({ codeStatus: 'Failed to save', errorMessage: error.message });
      console.error('Failed to save code:', error);
    } finally {
      this.saving = false;
    }
  };

  getPythonPayload() {
    const { active_level, codegen, workflow_items } = this.state;
    const prompt = `User Question: ${this.state.instructions}\nMy current Code: ${codegen}`;

    return {
      question: prompt,
      mop_name: this.state.mop_name,
      id: this.state.id,
      action: "edit",
      version: "v1",
      inputdata: "{'in_type': '','in_api': '','in_query_id': ''}",
      description: "this is a autocode api",
      codegen: codegen,
      username: "admin",
      modelname: "mistral"
    };
  }
  handlePythonCodeExecuteInterval = (interval) => {
    if (this.timerId) clearInterval(this.timerId); // Clear any existing interval
    this.timerId = setInterval(() => {
      this.handlePythonCodeExecute();
    }, interval * 1000); // Convert seconds to milliseconds
  }
  handlePythonCodeExecute = async () => {
    this.setState({ isExecuteLoading: true });
    const payload = this.getPythonPayload();
    payload["action"] = "execute";
    try {
      const res = await this.api.autocode(payload);
      var $html_content = <div dangerouslySetInnerHTML={{ __html: res.data[0].execution_log + res.data[0].result }} />
      console.log($html_content);
      this.setState({
        isExecuteLoading: false,
        execution_log: $html_content
      });
    } catch (error) {
      this.setState({ isExecuteLoading: false });
      console.error('Failed to execute code:', error);
    }
  };

  handleExpand = () => {
    console.log("Expand clicked")
    $(".code-section").toggleClass("expanded");
    if (this.props.parent) {
      // this.props.parent.handleShowEditorPythonModal(this.props.parent.EditorPython);
    }
  };

  handleEditWithAI = async () => {
    this.setState({ loadingCodeGeneration: true });
    const payload = this.getPythonPayload();
    payload["action"] = "generate";
    try {
        const res = await this.api.autocode(payload);
        this.setState({ codegen: res.data[0].result.program_generated });
        this.setState({ loadingCodeGeneration: false });
    } catch (error) {
      this.setState({ loadingCodeGeneration: false });
      console.error('Failed to generate code:', error);
    }
  };

  updateCode = (newCode) => {

    console.log("here")
      this.setState({ codegen: newCode });
      this.setState({ codeStatus: 'Saving...', errorMessage: '' });
      this.debouncedSaveCode();
  };

  handlePromptChange = (prompt) => {
      this.setState({ instructions: prompt.target.value });
      this.setState({ codeStatus: 'Saving...', errorMessage: '' });
      this.debouncedSaveCode();
  };

  handleChangeName = (prompt) => {
    this.setState({ mop_name: prompt.target.value });
    this.setState({ codeStatus: 'Saving...', errorMessage: '' });
    this.debouncedSaveCode();
};

  handleExpandHeader = () => {
    this.setState(prevState => ({ isCollapsedHeader: !prevState.isCollapsedHeader }));
  };

  render() {
    const { items } = this.props;
    const { activeTab, codegen, instructions, codeStatus, errorMessage, isExecuteLoading, loadingCodeGeneration, isCollapsedHeader } = this.state;

    if (!items || items.length < 1) {
    //   return null;
    }

    let statusIcon;
    let tooltipMessage = '';

    switch (codeStatus) {
      case 'Saving...':
        statusIcon = <FontAwesomeIcon icon={faSave} className="text-warning" />;
        tooltipMessage = 'Saving...';
        break;
      case 'Saved':
        statusIcon = <FontAwesomeIcon icon={faCheckCircle} className="text-success" />;
        tooltipMessage = 'Code saved to 3m';
        break;
      case 'Failed to save':
        statusIcon = (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-error">{errorMessage}</Tooltip>}
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" />
          </OverlayTrigger>
        );
        tooltipMessage = errorMessage;
        break;
      default:
        statusIcon = null;
    }

    return (
        <div className="d-flex justify-content-start">
            <Sidebar items={sidebarItems} />

            <div className='flex-fill'>
                <div className='d-flex justify-content-between'>
                    <div className='row'>
                        <InputGroup className="col">
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faPython} className="text-success" />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="filename"
                                aria-label="filename"
                                onChange={this.handleChangeName.bind(this)}
                                value={this.state.mop_name}
                                aria-describedby="basic-addon1"
                            />
                            </InputGroup>
                            <div className='p-2 col'>
                                { 
                                    (this.state.codeStatus) 
                                    ? <span pill bg="primary p-2 px-4">
                                    {tooltipMessage} {statusIcon} </span> : ""
                                }
                        
                            </div>
                        
                    </div>

                    <div className='action-buttons'>
                        
                    </div>
                </div>

                <div className={`EditorPython h-100 ${isCollapsedHeader ? 'collapsed' : ''}`}>
                
                <main className="EditorPython-main mt-2">
                <PythonEditor
                    handleExpand={this.handleExpand}
                    handleCodeChange={this.updateCode}
                    currentCode={codegen}
                    codeStatus={codeStatus}
                    errorMessage={errorMessage}
                    handleEditWithAI={this.handleEditWithAI}
                    currentPrompt={instructions}
                    handlePromptChange={this.handlePromptChange}
                    isExecuteLoading={isExecuteLoading}
                    handlePythonCodeExecute={this.handlePythonCodeExecute}
                    handlePythonCodeExecuteInterval={this.handlePythonCodeExecuteInterval}
                    loading={loadingCodeGeneration}
                    api={this.api}
                />
                <div className="EditorPython-section px-1 result-section mb-5">
                    <ResultArea result={this.state.execution_log} />
                </div>
               
                </main>
            </div>
            </div>
            </div>
    );
  }
}

const EditorPythonWrapper = () => {
    const { id } = useParams();
    return <EditorPython id={id} />;
  };
  
export default EditorPythonWrapper;
