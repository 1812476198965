import React, { Component } from 'react';
import { Container, Stack, Button, Form, InputGroup, Image, Modal, Navbar } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WorkflowActionsList from './ActionsList';
import $ from 'jquery';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BaseRequest from '../../Api/Base';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import "../../common/AppNavbar.css";
import { toast } from '../../utils';
import { workflow_apps } from '../../config/app';
import Editor from './Editors/Editor';
import debounce from 'lodash.debounce';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Sidebar from './Sidebar';
import Breadcrumb from '../components/breadcrumbs';
import { faCogs } from '@fortawesome/free-solid-svg-icons';

const WorkflowCreateWrapper = (props) => {
  const { id } = useParams();
  return <WorkflowCreate id={id} {...props} />;
};

class WorkflowCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEditorModal: false,
      bootInput: '',
      workflowContent: [],
      workflow_items: [],
      workflow: [],
      promptOutput: null,
      active_level: 0,
      activeTab: null,
      code: '',
      prompt: '',
      title: '',
      itemsStatus: '',
      showEditWorkflowModal: false,
      itemNames: {}, // Add this to track the item names
      workflowLoading:true,
    };
    this.api = new BaseRequest();
    this.debouncedWorkflowItemsSave = debounce(this.handleSaveWorkflow, 1000);
  }

  async update_workflow_info(id) {
    this.setState({
      workflowLoading:true,
    });
    const workflow = await this.api.workflow(`get/${id}`, [], "GET");
    this.setState({
      workflow: workflow,
      workflowLoading:false,
    });

    return workflow
  }

  async componentDidMount() {
    console.log('Component Mounted');
    const { id } = this.props;
    await this.update_workflow_info(id)
    const workflow_items = await this.api.workflow_items(`${id}`, [], "GET");

    this.setState({
      workflow_items: workflow_items,
    });

  }

  handleResize = () => {
    console.log("handleResize::");
  };

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.debouncedWorkflowItemsSave.cancel();
  }

  handleItemClick = (item) => {
    this.setState({
      activeTab: "level_" + item.level + "_" + item.item_id,
      item_id: item.item_id,
      item_level: item.level,
      code: item.code,
      prompt: item.question,
    });
    console.log(item, this.state);
  }

  handleInputChange = (event, level) => {
    const { value } = event.target;
  
    this.setState((prevState) => ({
      workflow_items: prevState.workflow_items.map((item) => 
        item.level === level ? { ...item, item_name: value } : item
      ),
    }));
  
    this.debouncedWorkflowItemsSave();
  };
  
  handleDeleteWorkflowItem = (item) => {
    const updatedItems = this.state.workflow_items.filter((element) => element.level !== item.level);
    this.setState({ workflow_items: updatedItems });
    this.debouncedWorkflowItemsSave();
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleExecuteWorkflow = async () => {
    this.setState({ promptOutput: <div>Loading...</div> });

    let latestResult = this.state.bootInput;
    var $payload = {
      recent_result: latestResult
    };
    const response = await this.api.workflow("run/" + this.state.workflow.id, $payload, "POST");
    console.log(response);
    
    this.setState({ 
      promptOutput:  <pre className='text-start'>{JSON.stringify(response, null, 2)}</pre>
    });
  };

  handleSaveWorkflow = async () => {
    this.setState({ itemsStatus: 'Saving...', errorMessage: '' });

    const apps = this.state.workflow_items.map((item, level) => ({
      app_id: item.app_id,
      item_id: item.item_id,
      item_name: this.state.itemNames[item.id] || item.item_name,
      level: level,
    }));
    const payload = {
      workflow_id: this.state.workflow.id,
      apps: apps,
    };
    const result = await this.api.workflow("items/create", payload, "POST");
    this.setState({ itemsStatus: 'Saved' });

    toast("Logic saved...");
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  handleDeploy = async () =>  {
    var $payload = {
      id: this.state.workflow.id,
      workflowName: this.state.workflow.name,
      timeout: this.state.workflow.timeout,
      execution_interval: this.state.workflow.execution_interval,
      status: "published",
    }

    var $schdule_payload = {
      id: this.state.workflow.id,
      interval_seconds: this.state.workflow.execution_interval,
    }
    var $res = await this.api.schedule_workflow($schdule_payload);
    if ($res.message) {
      toast($res.message, "success");
    }
    // var result = await this.handleSaveWorkflowSettings($payload, "published")
    // console.log(result)
  };

  editHeader = () => {
    const { title, isEditing } = this.state;

    return (
      <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top custom-navbar">
        <Container>
          <Navbar.Brand>
            {(
              <span className="editable-title" >
                {this.state.workflow.name ?? "draft"}
              </span>
            )}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Button variant="outline-light" onClick={this.handleDeploy}>
              Deploy 
            </Button>
          </Nav>
        </Container>
      </Navbar>
    );
  };

  getWorkflowItems() {
    if (!this.state.workflow_items) {
      return;
    }
    return <div className='list-group m-auto workflow-item'>
      {this.state.workflow_items.map((element) => (
      <div key={element.id}>
        <div className='list-group-item my-1 d-flex' >
          <div className="col-3 d-flex">
            <Image src={workflow_apps[element.app_id].img} rounded height={50} className='m-auto' />
          </div>
          <div className='col-9 text-truncate'>
            <div className='col-9'>
              <input className='w-100 d-block' type="text" value={this.state.itemNames[element.id] || element.item_name}
                placeholder="name" onChange={(e)=> this.handleInputChange(e, element.level)}
              />
            </div>
            <span className="badge bg-info">{workflow_apps[element.app_id].name}</span>
            <div className='top-right' onClick={(e)=> { e.stopPropagation(); this.handleDeleteWorkflowItem(element); }}>
              <HighlightOffIcon />
            </div>
          </div>
          <i className="fa-solid fa-arrow-down"></i>
        </div>
        <FontAwesomeIcon className='d-flex m-auto' icon={faArrowDown} />
      </div>
      ))
      }
    </div>
  }

  showEditWorkflowModal() {
    this.setState({
      showEditWorkflowModal: true
    })
  }

  hideEditWokflowModal() {
    this.setState({
      showEditWorkflowModal: false
    })
  }

  async handleSaveWorkflowSettings(workflowData, status = "draft") {
    console.log(workflowData, this.props.id)

    const $payload = {
      name: workflowData.workflowName,
      status: status,
      timeout: parseInt(workflowData.timeout, 10), // Ensure timeout is an integer
      execution_interval: parseInt(workflowData.execution_interval, 10), // Ensure execution_interval is an integer
    };

    // await this.update_workflow_info(this.props.id)

    // Call the API
    const response = await this.api.workflow(
      "edit/" + this.props.id, 
      $payload,
      "PUT"
    );

      toast(response.message)
  }

  render() {
    var $editor = (
      <Editor
        items={this.state.workflow_items}
        activeTab={this.state.activeTab}
        parentState={this.state}
        parent={this}
      />
    );
    const breadcrumbItems = [
      { name: 'Workflow List', url: '/logic-flow/lists' },
      { name: 'Edit', url: null }
    ];

    var $edit_workflow_props = false;
    if (this.state.workflowLoading === false) {
      var $edit_workflow_props = {
          showModal: this.state.showEditWorkflowModal,
          onClose: this.hideEditWokflowModal.bind(this),
          onSave: this.handleSaveWorkflowSettings.bind(this),
          workflow: this.state.workflow,
          title: <FontAwesomeIcon icon={faCogs} className="h4" />,
        }
    } 

    var $sidebar = <></>;
    if (!$.isEmptyObject(this.state.workflow)) {
      $sidebar = <Sidebar 
          edit_workflow_props={$edit_workflow_props}
      />
    }

    return (
      <div>
        <Breadcrumb items={breadcrumbItems} />
        <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          {$sidebar}
        </div>
        <div className="container-fluid p-0 flex-fill w-100">
        {this.editor_modal($editor)}

        <div className='d-flex h-max'>
          <PanelGroup autoSaveId="example" direction="horizontal" tagName="div">
            <Panel defaultSizePercentage={2} collapsedSize={10} collapsible={false} minSize={35}>
              <Container fluid className='p-0'>
                {this.editHeader()}
                <Stack className='pt-3'>
                  <div className='px-5'>
                    
                    {/* workflow items */}
                    {this.getWorkflowItems()}

                    <AddCircleOutlineIcon className='d-block m-auto href' onClick={this.handleShowModal} />
                    <div className='text-center my-3'></div>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>Input</InputGroup.Text>
                      <Form.Control
                        placeholder="Boot information for workflow"
                        name="bootInput"
                        value={this.state.bootInput}
                        onChange={this.handleChange}
                      />
                      <Button variant="primary" onClick={this.handleExecuteWorkflow}>
                        Run <i className="fa fa-play"></i>
                      </Button>
                    </InputGroup>
                    {/* <Button variant="primary" onClick={this.handleSaveWorkflow}>
                      Save <i className="fa fa-save"></i>
                    </Button> */}
                    <div className='mt-3'>
                      <strong>Results:</strong>
                      <div className='output p-2'>{this.state.promptOutput}</div>
                    </div>
                  </div>
                </Stack>
                <WorkflowActionsList home={this} />
              </Container>
            </Panel>
            <PanelResizeHandle />
            {/* <Panel id='editor-window' ref={this.editorWindowRef}>
              {$editor}
            </Panel> */}
            <PanelResizeHandle />
          </PanelGroup>
        </div>
      </div>
      </div>
      </div>
  
    );
  }

  handleCloseEditorModal() {
    this.setState({ showEditorModal: false });
  }

  handleShowEditorModal() {
    this.setState({ showEditorModal: true });
  }

  editor_modal($editor) {
    return (
      <div className='text-start'>
        <Modal 
            fullscreen={true}
            show={this.state.showEditorModal} 
            onHide={this.handleCloseEditorModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.workflow.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {$editor}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default WorkflowCreateWrapper;
