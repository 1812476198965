import React from "react";
import "./App.css";
import AppNavbar from "./common/header";
import { Link } from "react-router-dom"; // Import Link from React Router
import { APPS } from "./config/app";
import Card from "./common/card";
import FinancialData from "./pages/FinancialData";

function Homepage() {
  return (
    <div className="App">
      <div className="">
        {/* {APPS.map((app, index) => (
          <Link key={index} to={app.url} className="card-link">
            <Card name={app.name} description={app.description} />
          </Link>
        ))} */}

        <FinancialData />
      </div>
    </div>
  );
}

export default Homepage;
