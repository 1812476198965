import * as monaco from 'monaco-editor';
import { useEffect, useRef } from 'react';

const MonacoEditor = ({ code, onChange }) => {
  const editorRef = useRef(null); // Reference to the div that holds the editor
  const monacoInstance = useRef(null); // Reference to the Monaco editor instance

  useEffect(() => {
    if (editorRef.current && !monacoInstance.current) {
      // Create Monaco Editor only if it hasn't been initialized yet
      monacoInstance.current = monaco.editor.create(editorRef.current, {
        value: code || '', // Ensure initial code value is an empty string if undefined
        language: 'python',
        theme: 'vs-dark',
        wordWrap: 'wordWrapColumn',
        automaticLayout: true, // Automatically adjust layout when container resizes
      });

      // Add the onChange listener
      monacoInstance.current.onDidChangeModelContent(() => {
        const updatedCode = monacoInstance.current.getValue();
        if (onChange) {
          onChange(updatedCode); // Trigger the onChange function passed in props
        }
      });
    }

    return () => {
      // Cleanup Monaco instance on unmount to prevent memory leaks
      if (monacoInstance.current) {
        monacoInstance.current.dispose();
        monacoInstance.current = null;
      }
    };
  }, []); // Empty array to ensure this runs only on component mount

  useEffect(() => {
    // Update editor's value only when the `code` prop changes
    if (monacoInstance.current && typeof code === 'string') {
      const currentValue = monacoInstance.current.getValue();
      if (currentValue !== code) {
        monacoInstance.current.setValue(code); // Set Monaco editor content to updated code
      }
    }
  }, [code]); // Dependency array with `code` to watch for changes

  return <div ref={editorRef} id='monaco-editor'  />;
};

export default MonacoEditor;
