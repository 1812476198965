import React, { Component } from 'react';
import { Table, Spinner, Form, Button } from 'react-bootstrap';
import AppNavbar from '../common/header';
import BaseRequest from '../Api/Base';
import "./css/common.css"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';
import { toast } from '../utils';
import ModalDialog from './components/modal';
import SearchForm from './components/search_box';

class InstrumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruments: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: '',
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.fetchInstruments = this.fetchInstruments.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.fetchInstruments();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async fetchInstruments() {
    if (this.state.loading || !this.state.hasMore) return; // Prevent multiple calls
    // this.setState({ loading: true });
    this.state.loading = true;
    const { page, searchQuery } = this.state;
    try {
      const data = await this.api.get('/api/market/instruments/list', { page, search: searchQuery });
      console.log('API response:', data); // Debugging line to check the structure of the response
  
      if (data && data.results) {
        this.setState((prevState) => ({
          instruments: [...prevState.instruments, ...data.results],
          hasMore: data.results.length > 0,
          loading: false,
        }));
      } else {
        console.error('Unexpected API response structure:', data);
        this.setState({ loading: false, hasMore: false });
      }
    } catch (error) {
      console.error('Error fetching instruments:', error);
      this.setState({ loading: false });
    }
  }
  

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || this.state.loading) return;
    this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchInstruments);
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ instruments: [], page: 1, hasMore: true }, this.fetchInstruments);
  }

  render() {
    return (
      <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
      <div className="flex-fill mt-4 w-100">
          <h1 className="mb-4 text-center">Instruments</h1>
          <SearchForm
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
            placeholder="Enter symbol..."
            className="my-custom-class"
          />
          {this.getInstruments()}
        </div>
      </div>
    );
  }

  async publish_instrument(instrument) {
    console.log(instrument)
    var $symmbol = instrument.exchange + ":" + instrument.tradingsymbol;
    var $payload = {
      tradingsymbol: $symmbol,
      instrument_token: $symmbol,
      type: instrument.instrument_type,
      source: "instrument-list"
    }

    var $res = await this.api.publish_instrument($payload);
    if ($res.success) {
      toast($res.success, "success")
    }
    else if ($res.response && $res.response.data.detail) {
      toast($res.response.data.detail, "error")

    }

    // if (confirm("Are you sure to publish this instrument")) {
    // }
  }

  getInstruments() {
    return (
      <div className="table-container ">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Instrument Token</th>
              <th>Trading Symbol</th>
              <th>Publish</th>
              <th>Name</th>
              <th>Last Price</th>
              <th>Expiry</th>
              <th>Type</th>
              {/* Add more headers as needed */}
            </tr>
          </thead>
          <tbody>
            {this.state.instruments.map((instrument) => (
              <tr key={instrument.instrument_token}>
                <td>{instrument.instrument_token}</td>
                <td>{instrument.tradingsymbol}</td>
                {/* <td><Button variant='primary' onClick={this.publish_instrument.bind(this, instrument)} className='mx-2' size='sm'> {"publish"} </Button></td> */}
                <td>
                  <ModalDialog info={{
                    title: "Are you sure to publish instruments",
                    onSave: this.publish_instrument.bind(this, instrument),
                    name: "Publish",
                    content: <>
                      <div>Symbol name: {instrument.tradingsymbol}</div>
                    </>
                    
                  }} />
                </td>
                <td>{instrument.name}</td>
                <td>{instrument.last_price}</td>
                <td>{instrument.expiry}</td>
                <td>{instrument.instrument_type}</td>
                {/* Add more columns as needed */}
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.loading && (
          <div className="text-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!this.state.hasMore && !this.state.loading && (
          <p className="mt-3 text-center">No more instruments to load.</p>
        )}
      </div>
    );
  }
}

export default InstrumentList;
