import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Login() {
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");

    if (code) {
      handleCallback(code);
    }
  }, [location]);

  const handleCallback = async (code) => {
    try {
      const response = await axios.get(`/api/auth/callback`, {
        params: { code },
      });
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data)); // Store user data
    } catch (error) {
      console.error("Error during callback:", error);
    }
  };

  const handleLogout = () => {
    axios.get("/api/auth/logout").then(() => {
      setUser(null);
      localStorage.removeItem("user"); // Clear stored data
    });
  };

  return (
    <div>
      <h1>Google OAuth2 Login with React and FastAPI</h1>
      {!user ? (
        <button onClick={() => window.location.href = "/api/auth/login"}>
          Login with Google
        </button>
      ) : (
        <>
          <div>
            <h3>Welcome, {user.name}</h3>
            <img src={user.picture} alt="User Profile" />
          </div>
          <button onClick={handleLogout}>Logout</button>
        </>
      )}
    </div>
  );
}

export default Login;
