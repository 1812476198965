// Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCogs } from '@fortawesome/free-solid-svg-icons';
import "../css/sidebar.css";
import EditWorkflowModal from './components/EditWorkflow';
import { Button, Modal, Form } from 'react-bootstrap';

const Sidebar = ($edit_workflow_props) => {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  console.log($edit_workflow_props)
  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={`sidebar p-0 py-3 auto`} style={{width: "50px"}} >
        <a href='/'><img src="/logos/3m.png" width={40} alt="Logo" className="sidebar-logo" /></a>
        <div className="sidebar-items py-4">
          {/* <Link to="/settings" className="sidebar-item w-100 m-auto text-center d-block">
            <FontAwesomeIcon icon={faCogs} className="sidebar-item-icon h4" />
          </Link> */}
          {/* {$edit_workflow_props} */}
          <EditWorkflowModal {...$edit_workflow_props} 
              btn_modal={ <div className="text-center mt-4"
                onClick={$edit_workflow_props.onClose}
                id="btn-create-workflow"
              >
                <FontAwesomeIcon icon={faCogs} className="sidebar-item-icon h4" />
              </div>
            }
            
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
