import React, { Component } from 'react';
import { Table, Spinner, Form } from 'react-bootstrap';
import BaseRequest from '../Api/Base';
import "./css/common.css";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'; // lodash for debouncing
import moment from 'moment';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';

class MarketEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruments: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: '',
    };

    this.fetchInstruments = this.fetchInstruments.bind(this);
    this.handleSearchChange = _.debounce(this.handleSearchChange.bind(this), 300); // Debounce search input
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleScroll = _.throttle(this.handleScroll.bind(this), 300); // Throttle scroll to improve performance
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.fetchInstruments();
    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up listener
  }

  async fetchInstruments() {
    if (this.state.loading || !this.state.hasMore) return; // Prevent multiple calls

    this.setState({ loading: true });
    const { page, searchQuery } = this.state;

    try {
      const data = await this.api.get('/api/market/market-events/', {
        page,
        search: searchQuery,
        limit: 100,
      });

      console.log('API response:', data); // Debugging line to check the structure of the response

      if (data && data.length > 0) {
        this.setState((prevState) => ({
          instruments: [...prevState.instruments, ...data],
          hasMore: data.length === 100, // Assume there might be more if we get 100 results
          loading: false,
        }));
      } else {
        this.setState({ loading: false, hasMore: false });
      }
    } catch (error) {
      console.error('Error fetching instruments:', error);
      this.setState({ loading: false });
    }
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight) {
      this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchInstruments);
    }
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value }, () => {
      if (this.state.searchQuery.length > 2 || this.state.searchQuery.length === 0) {
        this.setState({ instruments: [], page: 1, hasMore: true }, this.fetchInstruments);
      }
    });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ instruments: [], page: 1, hasMore: true }, this.fetchInstruments);
  }

  render() {
    return (
      <div className="d-flex">
        <Sidebar items={sidebarItems} />
        <div className="flex-fill mt-4">
          <Form onSubmit={this.handleSearchSubmit} className="search-form">
            <div className="search-group">
              <Form.Control
                type="text"
                placeholder="Search Trading Symbol"
                value={this.state.searchQuery}
                onChange={this.handleSearchChange}
                className="search-input border-0"
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
            </div>
            <button type="submit" className="btn btn-primary search-button">Search</button>
          </Form>
          <h1 className="mb-4 px-2">Market Events Alerts</h1>
          {this.getInstruments()}
        </div>
      </div>
    );
  }

  getInstruments() {
    return (
      <div className="table-container">
        <Table striped bordered hover responsive="lg">
          <thead>
            <tr>
              <th>Time Triggered</th>
              <th>Event Code</th>
              <th>Symbol</th>
              <th>Event Name</th>
              <th>Start time</th>
              <th>Event Details</th>
            </tr>
          </thead>
          <tbody>
            {this.state.instruments.map((instrument) => (
              <tr key={instrument.id}>
                <td>{moment(instrument.event_start_time).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{instrument.event_code}</td>
                <td>{instrument.symbol_name}</td>
                <td>{instrument.event_name}</td>
                <td>{instrument.event_start_time}</td>
                <td>{instrument.event_details}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.loading && (
          <div className="text-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!this.state.hasMore && !this.state.loading && (
          <p className="mt-3 text-center">No more instruments to load.</p>
        )}
      </div>
    );
  }
}

export default MarketEvents;
