import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';  // Your main app component
import Login from './Login';
import { isLoggedIn } from './hooks/auth';  // Import utility function

const Main = () => {
    const loggedIn = isLoggedIn();

    return ( loggedIn ? <App /> : <Login />);
};

ReactDOM.render(<Main />, document.getElementById('root'));
