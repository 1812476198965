import React, { useState, useEffect, useRef } from 'react';
// import { CodeiumEditor } from "@codeium/react-code-editor";

import { faExpandAlt, faRocket, faCaretDown, faCaretUp, faPlayCircle, faCircleInfo, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Button, Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import SlimSelect from 'slim-select';
import "../../../../pages/css/slimselect.min.css"
import IntervalDropdown from '../../../components/window_size';
import MonacoEditor from './MonacoEditor';
let slimSelectInstance = null; // Declare the instance outside to ensure global access.

const PythonEditor = ({
    handleExpand,
    handleCodeChange,
    currentCode,
    codeStatus,
    errorMessage,
    handleEditWithAI,
    currentPrompt,
    handlePromptChange,
    isExecuteLoading,
    handlePythonCodeExecute,
    handlePythonCodeExecuteInterval,
    loading,
    api
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isConfigCollapsed, setIsConfigCollapsed] = useState(false);
  const [interval, setInterval] = useState(1); // Default to 1 second
  const [isRunning, setIsRunning] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const selectRef = useRef(null);
  var tickers = [
    { text: 'Option 1', value: 'value1' },
    { text: 'Option 2', value: 'value2' }
  ];
// Fetch and update symbols based on type

  // Fetch and update symbols based on type
  const updateSymbols = (type) => {
    if (selectRef.current) {
      api.get_symbols({ type })
        .then(response => {
          const symbols = response.data.map(instrument => ({
            text: instrument.tradingsymbol,
            value: instrument.tradingsymbol
          }));

          // Ensure SlimSelect is initialized before trying to destroy
          if (slimSelectInstance) {
            if (typeof slimSelectInstance.destroy === 'function') {
              slimSelectInstance.destroy();
            } else {
              console.error("SlimSelect instance doesn't support destroy()");
            }
          }

          // Reinitialize SlimSelect with the new data
          slimSelectInstance = new SlimSelect({
            select: selectRef.current,
            data: symbols // Use new symbols from the API
          });
        })
        .catch(error => {
          console.error("Error fetching symbols", error);
        });
    }
  };

  // Handle type change
  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSelectedType(type);
    updateSymbols(type); // Update symbols on type change
  };

  // Initialize SlimSelect and set cleanup logic


// Initialize SlimSelect and set cleanup logic
useEffect(() => {
  if (selectRef.current) {
    // Initialize SlimSelect with default tickers
    slimSelectInstance = new SlimSelect({
      select: selectRef.current,
      data: tickers
    });

    updateSymbols("EQ")
  }

  // Cleanup function
  return () => {
    if (slimSelectInstance) {
      slimSelectInstance.destroy(); // Properly clean up the SlimSelect instance
    }
  };
}, []); // Empty dependency array ensures this runs only once when the component mounts


  // Handle symbol selection
  const handleSymbolChange = (event) => {
    const value = event.target.value;
    setSelectedSymbol(symbolOptions.find(option => option.value === value) || null);
  };

  // Handle the interval selection
  const handleSelect = (event) => {
    const selectedInterval = parseInt(event.target.value, 10);
    setInterval(selectedInterval);
  };

  return (
    <div className="editor-section p-0 border-0 px-1 code-editor">

      <div className="d-flex justify-content-between align-items-center mb-2">
        <Button
          onClick={() => setIsConfigCollapsed(!isConfigCollapsed)}
          aria-controls="ai-config-section"
          aria-expanded={!isConfigCollapsed}
          variant="light"
        >
          {isConfigCollapsed ? (
            <>
              <FontAwesomeIcon icon={faCaretDown} /> CONFIG
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCaretUp} /> Hide Config
            </>
          )}
        </Button>
      </div>

      <Collapse
      in={!isConfigCollapsed}
      >
        <div id="ai-config-section" className="ai-config-section">
          <div className='d-flex'>
            <InputGroup className="">
              <InputGroup.Text id="basic-addon1">TYPE</InputGroup.Text>
              <Form.Select
                aria-label="Select type"
                onChange={handleTypeChange}
                value={selectedType}
              >
                <option value="EQ">Financial Data</option>
                <option value="crypto">Cryptocurrency</option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="">
              <InputGroup.Text id="basic-addon1">Symbol</InputGroup.Text>
              <select
                ref={selectRef}
                onChange={handleSymbolChange}
                className="form-select"
                aria-label="Select symbol"
              >
                <option value="">Select symbol</option>
              </select>
            </InputGroup>

            <InputGroup className="">
              <IntervalDropdown />
            </InputGroup>

            <InputGroup className="">
              <InputGroup.Text id="basic-addon1">Window size</InputGroup.Text>
              <Form.Control
                placeholder="500"
                aria-label="500"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>

        </div>
      </Collapse>

      <div className="d-flex justify-content-between align-items-center mt-4">
        <Button
          onClick={() => setIsCollapsed(!isCollapsed)}
          aria-controls="ai-section"
          aria-expanded={!isCollapsed}
          variant="light"
        >
          {isCollapsed ? (
            <>
              <FontAwesomeIcon icon={faCaretDown} /> Edit with AI
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCaretUp} /> Hide Edit with AI
            </>
          )}
        </Button>
      </div>

      <Collapse in={!isCollapsed}>
        <div id="ai-section" className="ai-section">
          <h4>Edit with AI {!isCollapsed && (
            <Button
              onClick={handleEditWithAI}
              variant="primary"
              disabled={loading}
            >
              {loading ? 'Processing...' : <><FontAwesomeIcon icon={faRocket} /> Generate Code</>}
            </Button>
          )}</h4>
          <textarea
            value={currentPrompt}
            onChange={handlePromptChange}
            placeholder="AI editing area..."
            className="form-control"
          />
        </div>
      </Collapse>


      <div className="code-section mt-3">
        <h4 className="border-0 m-0">
          <span className='expanded-none'>CODE</span>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
              <Button size='sm' className='rounded-0' variant="primary" onClick={handlePythonCodeExecute}>
                {isExecuteLoading ? "Loading" : "Run"} <FontAwesomeIcon icon={faPlayCircle} className='text-white' />
              </Button>
              <div className='mx-2 d-flex align-items-center'>
                <Form.Select
                  aria-label="Run interval"
                  className='bg-primary text-white border-1 border-primary rounded-0'
                  onChange={handleSelect}
                  size='sm'
                  value={interval}
                >
                  <option value={0}>Run interval</option>
                  <option value={1}>1 sec</option>
                  <option value={2}>2 sec</option>
                  <option value={5}>5 sec</option>
                </Form.Select>
                <Button
                  className='rounded-0'
                  variant="primary"
                  size='sm'
                  onClick={() => handlePythonCodeExecuteInterval(interval)}
                >
                  {isRunning ? "Stop" : "Run"}
                </Button>
              </div>
            </div>

            <div className="ms-auto">
              <FontAwesomeIcon
                icon={faExpand}
                className="float-end pe-1"
                id='editor-expand-btn'
                onClick={handleExpand}
              />
            </div>
          </div>
        </h4>

        {/* <CodeiumEditor
          language="python"
          id="py-ide"
          theme="vs-dark"
          onChange={handleCodeChange}
          value={currentCode}
        /> */}

<MonacoEditor code={currentCode}
onChange={handleCodeChange}
/>

      </div>
    </div>
  );
};

export default PythonEditor;
