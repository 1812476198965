import React, { Component } from 'react';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  elderRay,
  ema,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  ElderRaySeries,
  LineSeries,
  MovingAverageTooltip,
  OHLCTooltip,
  SingleValueTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons
} from 'react-financial-charts';
import BaseRequest from '../../Api/Base';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Sidebar from '../components/LeftSidebar';
import sidebarItems from '../components/sidebarItems.json';
import { Form } from 'react-bootstrap';
import "./ChartsView.css"
import SearchForm from '../components/search_box';
import IntervalDropdown from '../components/window_size';

const pricesDisplayFormat = format('.2f');
const timeDisplayFormat = timeFormat('%d %b %Y %H:%M:%S'); // Updated time format

class ChartsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruments: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: 'BTCUSDT',
      interval: '1m', // Default interval
      data: [],
      ema7: null,
      ema21: null,
      elder: null,
      chartDimensions: { width: 800, height: 500 }, // Default dimensions
    };

    this.containerRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.sync_chart = this.sync_chart.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleIntervalChange = this.handleIntervalChange.bind(this);
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.sync_chart();
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Set initial size
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (this.containerRef.current) {
      var { offsetWidth: width, offsetHeight: height } = this.containerRef.current;
      width = width;
      height = height - 200;
      this.setState({
        chartDimensions: { width, height },
      });
    }
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || this.state.loading) return;
    this.setState((prevState) => ({ page: prevState.page + 1 }), this.sync_chart);
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleIntervalChange(event) {
    this.setState({ interval: event.target.value }, this.sync_chart);
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ instruments: [], page: 1, hasMore: true }, this.sync_chart);
  }

  humanize(timestampStr) {
    return moment(timestampStr).fromNow();
  }

  async sync_chart() {
    const payload = {
      symbol: this.state.searchQuery,
      window_size: this.state.interval,
    };

    try {
      const response = await this.api.get_klines(payload);
      console.log(response);

      if (!response.data || response.data.length === 0) {
        throw new Error('No data returned from API');
      }

      const chartData = response.data.map(item => {
        return {
          date: new Date(item.open_time), // Convert string to Date object
          open: parseFloat(item.open_price),
          high: parseFloat(item.high_price),
          low: parseFloat(item.low_price),
          close: parseFloat(item.close_price),
          volume: parseFloat(item.volume),
        };
      });
      chartData.sort((a, b) => a.date - b.date);

      const ema7 = ema()
        .id(1)
        .options({ windowSize: 7 })
        .merge((d, c) => { d.ema7 = c; })
        .accessor(d => d.ema7);

      const ema21 = ema()
        .id(2)
        .options({ windowSize: 21 })
        .merge((d, c) => { d.ema21 = c; })
        .accessor(d => d.ema21);

      const elder = elderRay();

      const calculatedData = elder(ema21(ema7(chartData)));
      this.setState({ data: calculatedData, ema7, ema21, elder });
    } catch (error) {
      console.error('Error fetching or processing data:', error);
    }
  }

  render() {
    const { data, ema7, ema21, elder, chartDimensions, interval } = this.state;
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(d => d.date);
    const { data: chartData, xScale, xAccessor, displayXAccessor } = ScaleProvider(data);

    const { width, height } = chartDimensions;
    const margin = { left: 0, right: 48, top: 0, bottom: 24 };

    const max = xAccessor(chartData[chartData.length - 1]);
    const min = xAccessor(chartData[Math.max(0, chartData.length - 100)]);
    const xExtents = [min, max + 5];

    const barChartHeight = height / 4;
    const elderRayHeight = 100;
    const chartHeight = height - barChartHeight - elderRayHeight;
    const elderRayOrigin = (_, h) => [0, h - elderRayHeight];
    const barChartOrigin = (_, h) => [0, h - barChartHeight];

    return (
      <div className="d-flex flex-row">
        <Sidebar items={sidebarItems} className="sidebar" />
        <div className="content-container flex-fill p-0">
          <div className="search-container d-flex">
          <SearchForm
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
            placeholder="Enter symbol..."
            className="my-custom-class"
          />
          <IntervalDropdown />
          </div>
          <div
            ref={this.containerRef}
            className="chart-container"
            style={{ position: 'relative', width: '100%', height: '100%' }}
          >
            {data.length > 0 && (
              <ChartCanvas
  height={height}
  ratio={3}
  width={width}
  margin={margin}
  data={chartData}
  displayXAccessor={displayXAccessor}
  xScale={xScale}  // Make sure this is a time scale
  xAccessor={xAccessor}
  xExtents={xExtents}  // Define x-extents correctly
  zoomAnchor={lastVisibleItemBasedZoomAnchor}
>
  <Chart
    id={2}
    height={barChartHeight}
    origin={barChartOrigin}
    yExtents={d => d.volume}
  >
    <BarSeries
      fillStyle={d => d.close > d.open ? "rgba(38, 166, 154, 0.3)" : "rgba(239, 83, 80, 0.3)"}
      yAccessor={d => d.volume}
    />
  </Chart>
  <Chart
    id={3}
    height={chartHeight}
    yExtents={d => [d.high, d.low]}
  >
    <XAxis showGridLines showTickLabel={false} />
    <YAxis tickFormat={pricesDisplayFormat} showGridLines />
    <CandlestickSeries />
    <LineSeries yAccessor={ema21.accessor()} strokeStyle={ema21.stroke()} />
    <CurrentCoordinate yAccessor={ema21.accessor()} fillStyle={ema21.stroke()} />
    <LineSeries yAccessor={ema7.accessor()} strokeStyle={ema7.stroke()} />
    <CurrentCoordinate yAccessor={ema7.accessor()} fillStyle={ema7.stroke()} />
    <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />
    <EdgeIndicator
      itemType="last"
      rectWidth={margin.right}
      fill={d => d.close > d.open ? "#26a69a" : "#ef5350"}
      lineStroke={d => d.close > d.open ? "#26a69a" : "#ef5350"}
      displayFormat={pricesDisplayFormat}
      yAccessor={d => d.close}
    />
    <MovingAverageTooltip
      origin={[8, 24]}
      options={[
        {
          yAccessor: ema21.accessor(),
          type: "EMA",
          stroke: ema21.stroke(),
          windowSize: ema21.options().windowSize
        },
        {
          yAccessor: ema7.accessor(),
          type: "EMA",
          stroke: ema7.stroke(),
          windowSize: ema7.options().windowSize
        }
      ]}
    />
    <ZoomButtons />
    <OHLCTooltip origin={[8, 16]} />
  </Chart>
  <Chart
    id={4}
    height={elderRayHeight}
    yExtents={elder.accessor()}
    origin={elderRayOrigin}
    padding={{ top: 8, bottom: 8 }}
  >
    <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
    <YAxis tickFormat={pricesDisplayFormat} />
    <MouseCoordinateX displayFormat={timeDisplayFormat} />  {/* Ensure correct time format */}
    <MouseCoordinateY rectWidth={margin.right} displayFormat={pricesDisplayFormat} />
    <ElderRaySeries yAccessor={elder.accessor()} />
    <SingleValueTooltip
      yAccessor={elder.accessor()}
      yLabel="Elder Ray"
      yDisplayFormat={d => `${pricesDisplayFormat(d.bullPower)}, ${pricesDisplayFormat(d.bearPower)}`}
      origin={[8, 16]}
    />
  </Chart>
  <CrossHairCursor />
</ChartCanvas>


            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ChartsView;
