import React, { Component } from 'react';
import { Table, Spinner, Form, Card, ListGroup, Button, Modal } from 'react-bootstrap';
import BaseRequest from '../../Api/Base';
import ".././css/common.css"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'; // lodash for debouncing
import moment from 'moment';
import ".././css/workflow.css"
import { toast } from "./../../utils"
import Sidebar from '../components/LeftSidebar';
import sidebarItems from '../components/sidebarItems.json';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditWorkflowModal from './components/EditWorkflow';
import SearchForm from '../components/search_box';

class WorkflowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflows: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: '',
      showModal: false,
      workflowName: '',
      timeout: 1,
      execution_interval: 1,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSaveWorkflow = this.handleSaveWorkflow.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // this.handleScroll = _.debounce(this.handleScroll.bind(this), 300); // Debounce handleScroll
    this.fetchWorkflows = this.fetchWorkflows.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.api = new BaseRequest();
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }


  handleClose() {
    this.setState({ showModal: false });
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  async handleSaveWorkflow (workflowData)  {
    console.log('Workflow Data:', workflowData);

    try {
      // Collect data from the component state
  
      const $payload = {
        name: workflowData.workflowName,
        status: "draft",
        timeout: parseInt(workflowData.timeout, 10), // Ensure timeout is an integer
        execution_interval: parseInt(workflowData.execution_interval, 10), // Ensure execution_interval is an integer
      };
  
      // Call the API
      const response = await this.api.workflow("create", $payload);
      
      if (response.id) {
        // Show success toast message
        toast('Workflow created successfully!');
        this.fetchWorkflows();
        this.handleClose(); // Close the modal
        // Optionally, refresh the workflows list or update the state
      } else {
        // Show error toast message if response indicates failure
        toast('Failed to create workflow. Please try again.', "error");
      }
    } catch (error) {
      // Show error toast message for any unexpected errors
      toast('An error occurred while creating the workflow. Please try again.', "error");
    }
  }
  


  componentDidMount() {
    this.fetchWorkflows();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async fetchWorkflows() {
    if (this.state.loading || !this.state.hasMore) return; // Prevent multiple calls
    // this.setState({ loading: true });
    this.state.loading = true;
    const { page, searchQuery } = this.state;
    console.log(page, searchQuery);
    try {
      const data = await this.api.workflow("list", {
        skip: 0,
        limit: 100,
      }, "GET");
      console.log('API response:', data); // Debugging line to check the structure of the response
  
      console.log(data.items)
      if (data && data.items) {
        this.setState((prevState) => ({
          // workflows: [...prevState.workflows, ...data.results],
          workflows: [...data.items],
          hasMore: data.items.length > 0,
          loading: false,
        }));
      } else {
        console.error('Unexpected API response structure:', data);
        this.setState({ loading: false, hasMore: false });
      }
    } catch (error) {
      console.error('Error fetching workflows:', error);
      this.setState({ loading: false });
    }
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || this.state.loading) return;
    console.log("scrolled....")
    this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchWorkflows);
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ workflows: [], page: 1, hasMore: true }, this.fetchWorkflows);
  }

  createModalContent() {
    return (
      <div>
        <EditWorkflowModal
          showModal={this.state.showModal}
          onClose={this.handleCloseModal}
          onSave={this.handleSaveWorkflow}
          title={<><AddCircleOutlineIcon /> <span>Create New Workflow</span></>}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="d-flex justify-content-start">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
        <div className="flex-fill mt-4 w-100">
          <h1 className="mb-4">Events Library</h1>
          <SearchForm
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
            placeholder="Enter symbol..."
            className="my-custom-class"
          />
          {this.createModalContent()}
          {this.geWorkflows()}
        </div>
      </div>
    );
  }

  geWorkflows() {
    return (
      
      <div className="list-container mt-1">
        {this.state.workflows.length === 0 ? (
          <div className="text-center my-5">
            <h3>No Workflows Found</h3>
            <p className="text-muted">It looks like you haven't created any workflows yet.</p>
            <Button
              variant="primary"
              onClick={this.handleShow}
              className="mt-3"
            >
              Create Your First Workflow
            </Button>
          </div>
        ) : (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.workflows.map((workflow) => (
                  <tr key={workflow.id}>
                    <td>
                      <a href={"edit/" + workflow.id} className="btn btn-link text-decoration-none">
                      {workflow.name}
                      </a>
                    </td>
                    <td>{moment(workflow.created_at).fromNow()}</td>
                    <td>{moment(workflow.updated_at).fromNow()}</td>
                    <td>
                      <a href={"edit/" + workflow.id} className="btn btn-link">
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {this.state.loading && (
              <div className="text-center my-3">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}
            {!this.state.hasMore && !this.state.loading && (
              <p className="mt-3 text-center">No more workflows to load.</p>
            )}
          </>
        )}
      </div>
    );
  }
}

export default WorkflowList;
